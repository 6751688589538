<template>
  <v-container fluid>
    <v-card
      flat
      outlined
    >
      <v-card-title> {{$t('passport.media')}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="d-flex justify-center flex-column align-center"
        style="height: 90vh"
      >
        <p>
          <v-icon>mdi-lock</v-icon>
        </p>
        <p>В планах на вторую итерацию</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'PassportMedia'
}
</script>

<style scoped>

</style>
